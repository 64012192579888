<template>
    <div :class="`p-block-wrapper ${passwordStrength}`">
        <div class="p-block" v-for="item in 3" :key="item"></div>
        <span class="strength-text">
            {{ $t('login.Password strength') }}：{{ passwordStrength || '_ _' }}
        </span>
    </div>
</template>
<script>
import { computed } from "@vue/composition-api";

export default {
    props: {
        password: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const passwordStrength = computed(() => {
            let strength = window.vm.$t('customerInfo.weak');
            if (props.password.length >= 8 && /[a-zA-Z]/.test(props.password) && /[0-9]/.test(props.password)) {
                if (/[a-zA-Z]/.test(props.password)) {
                    strength = window.vm.$t('customerInfo.weak');
                    if (
                        /[~!@#$%^&*()_+<>?:{},.`|]/.test(props.password) ||
                        (/[a-z]/.test(props.password) && /[A-Z]/.test(props.password))
                    ) {
                        strength = window.vm.$t('customerInfo.medium');
                        if (
                            /[a-z]/.test(props.password) &&
                            /[A-Z]/.test(props.password) &&
                            /[~!@#$%^&*()_+<>?:{},.`|]/.test(props.password)
                        ) {
                            return window.vm.$t('customerInfo.strong');
                        }
                    }
                }
            }
            return strength;
        });
        return {
            passwordStrength
        };
    }
};
</script>
<style>
.p-block-wrapper {
    display: flex;
    align-items: center;
    font-size: 12px;
    height: 16px;
    margin-top: 10px;
}

.p-block-wrapper.weak .p-block:nth-child(1),
.p-block-wrapper.弱 .p-block:nth-child(1) {
    background-color: #f56c6c;
}

.p-block-wrapper.medium > .p-block,
.p-block-wrapper.中 > .p-block {
    background-color: #ff8d1a;
}

.p-block-wrapper.medium > .p-block:nth-child(3),
.p-block-wrapper.中 > .p-block:nth-child(3) {
    background-color: #dfe2e6;
}

.p-block-wrapper.strong .p-block,
.p-block-wrapper.强 .p-block {
    background-color: #00baad;
}

.p-block {
    height: 4px;
    background-color: #dfe2e6;
    width: 21%;
}

.strength-text {
    flex: 1;
    margin-left: 20px;
    line-height: 1;
    color: #696F79;
    font-size: 12px;
    line-height: 17px;
}
</style>